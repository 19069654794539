// import { useState, useRef } from 'react';
import "./spunklist.css";
// import one from './pumpfun.mp4'
import sol from "./sol-spunk.gif";

// const spunkVideos = [three];

const SpunkList = () => {
  // const [currentVid, setCurrentVid] = useState(0);
  // const videoRef = useRef();

  // const onEnded = () => {
  //     setCurrentVid(currentVid < spunkVideos.length - 1 ? currentVid + 1 : 0);
  // }

  // const onClick = () => {
  //     if (videoRef?.current?.paused) {
  //         videoRef?.current?.play?.();
  //     } else {
  //         videoRef?.current?.pause?.();
  //     }
  // }

  return (
    <div className="spunk-list">
      <h1>SPUNK LIST</h1>
      <p>
        WE WILL BE PICKING AND SPUNKING ON OTHER COINS THAT WILL BE ADDED TO THE
        SPUNK LIST WEEKLY.
      </p>
      <p>WE WILL RAID THEIR COMMUNITIES AND MAKE OUR PRESENCE KNOWN.</p>
      <p>AINT NOBODY SAFE!</p>
      <img src={sol} alt="solana getting fucking spunked on"/>
      {/* <video autoPlay onEnded={onEnded} key={currentVid} ref={videoRef}>
                <source src={spunkVideos[currentVid]} type="video/mp4" />
            </video> */}
    </div>
  );
};

export default SpunkList;
