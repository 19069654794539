import logo from "./SPUNK.jpg";
// import logo from "./spunk.png";
import xLogo from "./logos/x.jpg";
import tgLogo from "./logos/tg.jpg";
import dexLogo from "./logos/dex.jpg";
import MarqueeText from "react-marquee-text";
import SpunkList from "./spunk-list";

import "./App.css";
// import SpunkGenerator from "./spunk-generator";
function App() {

  return (
    <div>
      <div className="App">
        <div className="marquee" style={{ zIndex: 1 }}>
          <MarqueeText playOnlyInView={false} direction={"right"} duration={25}>
            <p>
              BUY $PUNK OR GET SPUNKED ON! - BUY $PUNK OR GET SPUNKED ON! - BUY
              $PUNK OR GET SPUNKED ON! - BUY $PUNK OR GET SPUNKED ON! - BUY
              $PUNK OR GET SPUNKED ON! -{" "}
            </p>
          </MarqueeText>
        </div>
        <div className="external-links">
          <div className="left">
            <a
              href={"https://www.twitter.com/spunkonsol"}
              target="_blank"
              rel="noreferrer"
              alt="twitter"
            >
              <img src={xLogo} alt="twitter-logo" />
            </a>
            <a
              href={"https://t.me/spunkonsol"}
              target="_blank"
              rel="noreferrer"
              alt="telegram"
            >
              <img src={tgLogo} alt="telegram-logo" />
            </a>
            <a href={"/"} alt="coming soon">
              <img src={dexLogo} alt="dex-logo" />
            </a>
          </div>
          <div className="right">
            <a className="internal" href={"#spunk-map"}>
              SPUNK MAP
            </a>
            <a className="internal" href={"#spunk-list"}>
              SPUNK LIST
            </a>
          </div>
        </div>
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          {/* <p>CUMMING SOON!</p> */}
          <p>Spunky the Degenerate Rogue Sperm</p>
        </header>

        <p>MEET SPUNKY THE HORNY DEGENERATE SPERM WITH A REAL BAD TEMPER.</p>
      </div>
      <div className="App" id={"spunk-list"}>
        <SpunkList />
        {/* <SpunkGenerator /> */}
      </div>
    </div>
  );
}

export default App;
